import PropTypes from 'prop-types'
import React, { createContext, useState } from 'react'

// Create a order context
const OrdinationContext = createContext()

export function OrdinationProvider({ children }) {
  const [formSubmit] = useState([])
  return (
    <OrdinationContext.Provider value={formSubmit}>
      {children}
    </OrdinationContext.Provider>
  )
}

OrdinationProvider.propTypes = {
  children: PropTypes.object.isRequired,
}

export default OrdinationContext

import PropTypes from 'prop-types'
import React, { useState } from 'react'

// Create a order context
const TruthContext = React.createContext()

export function TruthProvider({ children }) {
  const [formSubmit] = useState([])
  return (
    <TruthContext.Provider value={formSubmit}>{children}</TruthContext.Provider>
  )
}

TruthProvider.propTypes = {
  children: PropTypes.object.isRequired,
}

export default TruthContext

import { useContext, useState } from 'react'
import OrdinationContext from '../components/contexts/OrdinationContext'

export default function useContact({ values }) {
  const [formSubmit] = useContext(OrdinationContext)
  // Create state to hold message
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('')

  async function submitContact() {
    setLoading(true)
    setError(null)
    // Gather data to send
    const body = {
      name: values.name,
      email: values.email,
      party: values.party,
      mealoptions: values.mealoptions,
      mapleSyrup: values.mapleSyrup,
    }
    // Send data to serverless function when they check out
    const res = await fetch(
      `${process.env.GATSBY_SERVERLESS_BASE}/ordinationEmail`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    )
    const checkText = JSON.parse(await res.text())
    // check if everything worked
    if (res.status >= 400 && res.status < 600) {
      setLoading(false) // turn off loading
      setError(checkText.success)
    } else {
      // it worked!
      setLoading(false)
      setSuccess('Success! Your message has been sent.')
    }
  }
  return {
    error,
    loading,
    success,
    submitContact,
    formSubmit,
  }
}

import PropTypes from 'prop-types'
import React, { useState } from 'react'

// Create a order context
const VbsContext = React.createContext()

export function VbsProvider({ children }) {
  const [formSubmit] = useState([])
  return (
    <VbsContext.Provider value={formSubmit}>{children}</VbsContext.Provider>
  )
}

VbsProvider.propTypes = {
  children: PropTypes.object.isRequired,
}

export default VbsContext

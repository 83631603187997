import React from 'react'
import { OrdinationProvider } from './src/components/contexts/OrdinationContext.js'
import Layout from './src/components/Layout'
import { VbsProvider } from './src/components/contexts/VbsContext.js'
import { TruthProvider } from './src/components/contexts/TruthContext.js'

export function wrapPageElement({ element, props }) {
  return <Layout {...props}> {element}</Layout>
}

export function wrapRootElement({ element }) {
  return (
    <OrdinationProvider>
      <VbsProvider>
        <TruthProvider>{element}</TruthProvider>
      </VbsProvider>
    </OrdinationProvider>
  )
}
